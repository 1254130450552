import styled from 'styled-components/macro';
import React from 'react';
interface Props {
  min?: number | string;
  max?: number | string;
  target?: string | number;
}

export const H1 = styled.h1<Props>`
  margin: 0;
  font-weight: 800;
  --minFontSize: ${(props) => props.min || '2rem'};
  --maxFontSize: ${(props) => props.max || '5rem'};
  --scaler: ${(props) => props.target || '8vw'};
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
`;

export const H2 = styled.h2<Props>`
  margin: 0;
  --minFontSize: ${(props) => props.min || '1.5rem'};
  --maxFontSize: ${(props) => props.max || '2.5rem'};
  --scaler: ${(props) => props.target || '4vw'};
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
`;

export const H3 = styled.h3<Props>`
  margin: 0;
  --minFontSize: ${(props) => props.min || '1rem'};
  --maxFontSize: ${(props) => props.max || '1.8rem'};
  --scaler: ${(props) => props.target || '3vw'};
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
`;

const StyledSectionTitleH2 = styled(H2)`
  background-color: orange;
  padding-right: 5rem;
  padding-left: 0.2rem;
  display: inline-block;
  margin-bottom: 3rem;

  /* PHONE STYLES */
  @media (max-width: 520px) {
    padding-right: 0rem;
  }
`;

export const SectionTitle: React.FC = (props) => {
  return (
    <StyledSectionTitleH2 min={'26px'} max={'32px'}>
      {props.children}
    </StyledSectionTitleH2>
  );
};
