import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HeroBanner from 'components/HeroBanner';
import Services from 'components/Services';
// import Testimonials from 'components/Testimonials';
import FeaturedPosts from 'components/Featured';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="About Me"
        description="A portfolio, resume, and blog for Koenn Becker, a software developer, photographer and graphic designer in Seattle Washington."
      />
      <HeroBanner />
      <Services />
      <hr />
      <FeaturedPosts />
    </Layout>
  );
};

export default IndexPage;
