import styled from 'styled-components/macro';
import { darken } from 'polished';
import tw from 'tailwind.macro';

export const Banner = styled.section`
  background-color: ${(p) => p.theme.colors.surface};
  border-color: ${(p) => darken(0.05, p.theme.colors.surface)};
  ${tw`border-b`};
`;

export const Content = styled.p`
  ${tw`mb-8`};
`;
