import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { SectionTitle } from 'helpers/definitions';
import LargeBanner from 'components/ui/LargeBanner';
import Socials from 'components/ui/Socials';

import * as Styled from './styles';

interface SectionHeroBanner extends SectionTitle {
  content: string;
  primaryLinkTo: string;
  primaryLinkText: string;
  secondaryLinkTo: string;
  secondaryLinkText: string;
}

const HeroBanner: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "hero section" } }) {
        frontmatter {
          preTitle
          title
          subtitle
          content
          primaryLinkTo
          primaryLinkText
          secondaryLinkTo
          secondaryLinkText
        }
      }
    }
  `);

  const heroBanner: SectionHeroBanner = markdownRemark.frontmatter;

  return (
    <div className="relative">
      <LargeBanner
        preTitle={heroBanner.preTitle}
        title={heroBanner.title}
        subtitle={heroBanner.subtitle}
        content={heroBanner.content}
        primaryLinkTo={heroBanner.primaryLinkTo}
        primaryLinkText={heroBanner.primaryLinkText}
        secondaryLinkTo={heroBanner.secondaryLinkTo}
        secondaryLinkText={heroBanner.secondaryLinkText}
      />
      <Styled.FixedSocials>
        <Socials />
      </Styled.FixedSocials>
    </div>
  );
};

export default HeroBanner;
