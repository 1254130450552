import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';

export interface StyledProps {
  primary?: boolean;
  block?: boolean;
}

export const Button = motion.custom(styled.button<StyledProps>`
  outline: none !important;
  border-color: ${(p) => p.theme.colors.primaryColor};
  color: ${(p) => p.theme.colors.text};
  ${tw`py-2 px-8 rounded-full border`};

  ${(p) =>
    p.primary
      ? `background-color: ${p.theme.colors.primaryColor}`
      : `color: ${p.theme.colors.secondaryColor}`};

  ${({ block }) => block && tw`w-full`};
`);
