import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Loadable from '@loadable/component';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { SectionTitle } from 'helpers/definitions';

import * as Styled from './styles';
import { Post } from 'components/Posts';
import { ContentCard } from 'components/ui/Card';

const Carousel = Loadable(() => import('components/ui/Carousel'));

const FeaturedPosts: React.FC = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { category: { ne: "dummy" }, featured: { eq: true } }
          fields: { collection: { eq: "blog" } }
          fileAbsolutePath: { regex: "/^((?!index.md).)*$/" }
          published: { eq: true }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              description
              date(formatString: "MMM DD, YYYY")
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 1280, maxHeight: 650, quality: 65, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const sectionTitle: SectionTitle = { title: 'Featured', subtitle: "What I've been working on" };
  const posts: Post[] = allMarkdownRemark.edges;

  return (
    <Container section wide={posts.length > 1}>
      <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />
      <Styled.FeaturedPosts>
        {posts.length > 1 ? (
          <Carousel numSlides={posts.length}>
            {posts.map((item) => {
              const {
                id,
                fields: { slug },
                frontmatter: { title, cover, description, date, tags },
              } = item.node;

              return (
                <ContentCard
                  key={id}
                  tags={tags}
                  slug={slug}
                  title={title}
                  cover={cover}
                  description={description}
                  date={date}
                />
              );
            })}
          </Carousel>
        ) : (
          <ContentCard
            className="w-full"
            key={posts[0].node.id}
            tags={posts[0].node.frontmatter.tags}
            slug={posts[0].node.fields.slug}
            title={posts[0].node.frontmatter.title}
            cover={posts[0].node.frontmatter.cover}
            description={posts[0].node.frontmatter.description}
            date={posts[0].node.frontmatter.date}
          />
        )}
      </Styled.FeaturedPosts>
    </Container>
  );
};

export default FeaturedPosts;
