import React from 'react';
import { Link } from 'gatsby';

import Container from 'components/ui/Container';
import Button from 'components/ui/Button';

import * as Styled from './styles';
import { H1, H2, H3 } from '../Text';

interface Props {
  preTitle?: string;
  title: string;
  subtitle?: string;
  content: React.ReactNode;
  primaryLinkTo: string;
  primaryLinkText: string;
  secondaryLinkTo: string;
  secondaryLinkText: string;
}

const LargeBanner: React.FC<Props> = ({
  preTitle,
  title,
  subtitle,
  content,
  primaryLinkText,
  primaryLinkTo,
  secondaryLinkText,
  secondaryLinkTo,
}) => (
  <Styled.Banner>
    <Container section className="z-50">
      <div className="leading-none">
        {preTitle && (
          <H3 as="h1" style={{ flexBasis: '100%', marginBottom: '0.75rem' }}>
            {preTitle}
          </H3>
        )}
        <H1 as="h2">{title}</H1>
        {subtitle && (
          <H2 as="h3" style={{ flexBasis: '100%', marginTop: '1rem', marginBottom: '1.5rem' }}>
            {subtitle}
          </H2>
        )}
      </div>
      <Styled.Content>{content}</Styled.Content>
      <Link
        to={primaryLinkTo}
        className="mb-4 mr-4"
        title={`Link to the ${primaryLinkTo.replace(/\//g, '')} page`}>
        <Button primary>{primaryLinkText}</Button>
      </Link>
      <Link to={secondaryLinkTo} title={`Link to the ${secondaryLinkTo.replace(/\//g, '')} page`}>
        <Button>{secondaryLinkText}</Button>
      </Link>
    </Container>
  </Styled.Banner>
);

export default LargeBanner;
